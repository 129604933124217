import Model from "../Model";

class Comments extends Model{

    buildUrl ({ action }) {
        return ['comment', action];
    }

    all(config = {}) {
        return this.request({ method: 'POST', action: 'index', ...config });
    }

    create(form) {
        return this.request({
            action: 'store',
            method: 'POST',
            data: form
        });
    }

    update(form) {
        return this.request({
            action: 'update',
            method: 'POST',
            data: form
        });
    }

    delete(form) {
        return this.request({
            action: 'destroy',
            method: 'POST',
            data: form
        });
    }
}


export default new Comments;